<!-- eslint-disable -->
<template>
    <span class="input__emoji" @mouseover="swapEmoji">
        {{currentEmoji}}
    </span>
</template>

<style scoped>
span {
    background: transparent;
    border: none;
    font-size: 1.2rem;
    transition: all 0.25s;
    filter: grayscale(1);
    user-select: none;
    margin-right: 0.25rem;
    cursor: pointer;
    padding: 0;
}

span:hover {
    transform: scale(1.15);
    filter: grayscale(0)
}
</style>

<script>
/* eslint-disable */
export default {
    data(){
        return {
            currentEmoji: "😄",
            emojis: ["😀", "😃", "😄", "😁", "😆", "😅", "🤣", "😂", "🙂", "🙃", "😉", "😊", "😇", "🥰", "😍", "🤩", "😘", "😗", "😚", "😙", "😋", "😛", "😜", "🤪", "😝", "🤑", "🤗", "🤭", "🤫", "🤔", "🤐", "🤨", "😐", "😑", "😶", "😏", "😒", "🙄", "😬", "🤥", "😌", "😔", "😪", "🤤", "😴", "😷", "🤒", "🤕", "🤢", "🤮", "🤧", "🥵", "🥶", "🥴", "😵", "🤯", "🤠", "🥳", "😎", "🤓", "🧐", "😕", "😟", "🙁", "😮", "😯", "😲", "😳", "🥺", "😦", "😧", "😨", "😰", "😥", "😢", "😭", "😱", "😖", "😣", "😞", "😓", "😩", "😫", "🥱", "😤", "😡", "😠", "🤬", "😈", "👿"]
        }
    },
    methods:{
        swapEmoji(){
            this.currentEmoji = this.emojis[Math.floor(Math.random()*this.emojis.length)]
        }
    }
}
</script>