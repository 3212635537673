<!-- eslint-disable -->
<template>
<div class="gif__picker__wrapper">
    <span class="gif__picker__button" @click="open = !open">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M19,4H5A3,3,0,0,0,2,7V17a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V7A3,3,0,0,0,19,4ZM5,18a1,1,0,0,1-1-1V14.58l3.3-3.29a1,1,0,0,1,1.4,0L15.41,18Zm15-1a1,1,0,0,1-1,1h-.77l-3.81-3.83.88-.88a1,1,0,0,1,1.4,0L20,16.58Zm0-3.24-1.88-1.87a3.06,3.06,0,0,0-4.24,0l-.88.88L10.12,9.89a3.06,3.06,0,0,0-4.24,0L4,11.76V7A1,1,0,0,1,5,6H19a1,1,0,0,1,1,1Z"/></svg>
    </span>
    <div class="gif__picker" :class="[open ? 'open' : '']">
        <div class="input">
            <input placeholder="Search for a GIF" @input="findGifs">
        </div>
        <div class="gif__picker--gifs">
            <gif
            v-for="gif in gifs"
            :gif="gif"
            :key="gif.id"
            :picker="true"
            @sendGif="sendGif">
            </gif>
        </div>
    </div>
</div>
</template>

<style scoped>
.gif__picker__button {
    background: transparent;
    border: none;
    margin-right: 0.25rem;
    padding: 0;
    cursor: pointer;
}

.gif__picker__button svg {
    height: 25px;
    color: rgb(221, 221, 221);
}

.gif__picker {
    position: absolute;
    height: 350px;
    overflow: auto;
    padding: 0 0.5rem;
    top: -370px;
    right: 0;
    width: 100%;
    display: none;
    background: #283046;
    border-radius: 0.25rem;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.gif__picker.open::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.gif__picker.open {
  -ms-overflow-style: none !important;  /* IE and Edge */
  scrollbar-width: none !important;  /* Firefox */
}
.gif__picker--gifs {
    column-count: 2;
    column-gap: 0.5rem;
}

.gif__picker.open {
    display: block;
}

.gif__picker .input {
    position: sticky;
    top: 0;
    width: calc(100% + 1rem);
    margin-left: -0.5rem;
    padding: 0.5rem;
    background: #283046;
}

.gif__picker input {
    width: 100%;
    margin-bottom: 0.25rem;
    padding: 0.4rem;
    border-radius: 0.25rem;
    color: white;
    background: #111727;
    border: 2px solid transparent;
    transition: border-color 0.2s;
}

.gif__picker input:focus {
    outline: none;
    border-color: #7367f0;
}
</style>

<script>
import gif from './gif.vue'
/* eslint-disable */
function debounce (fn, delay) {
  var timeoutID = null
  return function () {
    clearTimeout(timeoutID)
    var args = arguments
    var that = this
    timeoutID = setTimeout(function () {
      fn.apply(that, args)
    }, delay)
  }
}

export default {
  components: { gif },
    data(){
        return {
            gifs: [],
            searchTerm: false,
            open: false
        }
    },
    mounted(){
        this.findGifs(false)

        this.clickOpen = (e)=>{
            if(!this.open) return
            let t = e.target
            if(t.closest('.gif__picker__wrapper')) return
            this.open = false
        }

        document.addEventListener('click', this.clickOpen)
    },
    destroyed(){
        document.removeEventListener('click', this.clickOpen)
    },
    watch: {
        open: function (){
            if(this.open) return setTimeout(()=>this.$el.querySelector('input').focus(), 0)
        }
    },
    methods:{
        sendGif(id){
            this.$el.querySelector('input').value = ''
            this.open = false
            this.$emit('sendGif', id)
        },
        findGifs: debounce(function(e){
            let q = e ? e.target.value : false
            if(!q) q = 'pepe'
            fetch('https://api.brime.tv/v1/gifs/search?q=' + q)
            .then(r=>r.json())
            .then(data=>{
                let {gifs} = data
                this.gifs = gifs
            })
        }, 500)
    }
}
</script>