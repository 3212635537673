<template>
  <div class="wrapper">
    <div class="video__wrapper non-init" v-if="!generating">
      <video
        id="video"
        class="video"
        preload="all"
        width="940"
        autoplay
        type="application/x-mpegURL"
        playsinline
      />
      <div class="controls">
        <button
          class="seek-start clipButton"
          title="Skip to Start"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          ><path
            fill="currentColor"
            d="M13.41,12l3.3-3.29a1,1,0,1,0-1.42-1.42l-4,4a1,1,0,0,0,0,1.42l4,4a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42ZM8,7A1,1,0,0,0,7,8v8a1,1,0,0,0,2,0V8A1,1,0,0,0,8,7Z"
          /></svg>
        </button>
        <button
          class="play-control play clipButton"
          title="Play/Pause"
        >
          <svg
            class="svg--play"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          ><path
            fill="currentColor"
            d="M16,10.27,11,7.38A2,2,0,0,0,8,9.11v5.78a2,2,0,0,0,1,1.73,2,2,0,0,0,2,0l5-2.89a2,2,0,0,0,0-3.46ZM15,12l-5,2.89V9.11L15,12ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
          /></svg>
          <svg
            class="svg--pause"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          ><path
            fill="currentColor"
            d="M10,7A1,1,0,0,0,9,8v8a1,1,0,0,0,2,0V8A1,1,0,0,0,10,7Zm2-5A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20ZM14,7a1,1,0,0,0-1,1v8a1,1,0,0,0,2,0V8A1,1,0,0,0,14,7Z"
          /></svg>
        </button>
        <div class="time">
          <div class="current-time">
            00:00
          </div>
          <div class="time-divider">
            /
          </div>
          <div class="total-time">
            00:00
          </div>
        </div>
        <button
          class="seek-end clipButton"
          title="Skip to End"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          ><path
            fill="currentColor"
            d="M8.71,7.29A1,1,0,1,0,7.29,8.71L10.59,12l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l4-4a1,1,0,0,0,0-1.42ZM16,7a1,1,0,0,0-1,1v8a1,1,0,0,0,2,0V8A1,1,0,0,0,16,7Z"
          /></svg>
        </button>
      </div>
    </div>
    <div class="playback" v-if="!generating">
      <div class="playback__pin" />
      <div
        class="dual-range"
        :data-min="min"
        :data-max="max"
      >
        <span class="handle left" />
        <span class="highlight" />
        <span class="handle right" />
      </div>
      <div class="playback__thumbs" />
    </div>
    <b-row v-if="!generating">
    <b-col
      md="6"
      xl="8"
      class="mb-1 mt-2 center"
      style="max-width: 400px; margin-right: -30px;"
    >

      <!-- basic -->
      <b-form-group
      >
        <b-form-input
          id="basicInput"
          v-bind="title"
          spellcheck="false"
          placeholder="Clip Title"
        />
      </b-form-group>
    </b-col>
    <b-col
      md="12"
      xl="4"
      class="mb-1 mt-2 center"
    >
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="danger"
      size="md"
      @click="clipit"
    >
      Cancel
    </b-button>
     <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      class="ml-1"
      size="md"
      @click="clipit"
    >
      Create Clip
    </b-button>
  </b-col>
  </b-row>
    <img src="https://assets.brimecdn.com/generating_clip.gif" v-if="generating && !done" style="display: block; margin-left: auto; margin-right: auto; width: 50%;">
    <vue-plyr id="clipPlayer" ref="clipPlayer" :options="{autoplay: true, muted: false}" v-if="done"><video :src="clipURL" autoload controls width="100%"/></vue-plyr>
     <b-form-group
     v-if="done"
      >
    <span style="display: block; margin-top: 5px; margin-bottom: 5px;">LINK</span><b-form-input
          id="basicInput"
          :value="clipLink"
        />
        </b-form-group>
  </div>
</template>

<script>
/* eslint-disable */
import {BFormInput, BRow, BCol, BFormGroup, BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
   components: {
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    stream: {
      required: false
    }
  },
  data () {
    return {
    generating: false,
    done: false,
    title: '',
    clipURL: '',
    clipLink: '',
    min: null,
    max: null,
    }
  },
  created() {

  },
  async mounted() {
    let brimeTime = await this.getClipTime()
    this.max = brimeTime.time
    this.min = this.max - 120
    setTimeout(()=>{
      const url = 'https://clipping-edge.brimecdn.com/live/'
      const pThumbs = this.$el.querySelector('.playback__thumbs')
      const pW = this.$el.querySelector('.playback').clientWidth
      const tThumbs = Math.ceil(pW / 71)
      const incr = 120 / tThumbs
      for (let i = 0; i < tThumbs; ++i) {
        const img = document.createElement('img')
        img.src = `https://clipping-edge.brimecdn.com/live/${this.stream}/dvr_thumbnail_${Math.floor(this.min + (i * incr))}.jpg`
        pThumbs.appendChild(img)
      }

      const config = {
        autoStartLoad: true,
        startPosition: 0,
      }

      const video = this.$el.querySelector('[id="video"]')
      const videoSrc = `${url + this.stream}/playlist_dvr_range-${this.min}-` + '120.m3u8'
      if (Hls.isSupported()) {
        const hls = new Hls(config)
        hls.loadSource(videoSrc)
        hls.attachMedia(video)
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = videoSrc
      }

      const playbackBar = this.$el.querySelector('.playback')
      let playbackRect = null
      let seekRatio = null
      const progressBar = this.$el.querySelector('.playback__pin')
      let frontTrimmedRatio = 0
      let endTrimmedRatio = 1

      const player = {
        video: this.$el.querySelector('video'),
        start: 0,
        end: null,
        controls: {
          play: this.$el.querySelector('.play-control'),
          seekToStart: this.$el.querySelector('.seek-start'),
          seekToEnd: this.$el.querySelector('.seek-end'),
          reset: this.$el.querySelector('.reset-control'),
        },
      }

      const playVideo = async function () {
        if (video.paused) {
          try{
            await video.play()
          }catch(err){
            return
          }
          player.controls.play.classList.remove('play')
          player.controls.play.classList.add('pause')
        }
        if (video.currentTime >= player.end) {
          video.currentTime = player.start
          player.controls.play.classList.remove('play')
          player.controls.play.classList.add('pause')
        }
      }

      var pauseVideo = function () {
        video.pause()
        player.controls.play.classList.remove('pause')
        player.controls.play.classList.add('play')
        video.classList.remove('paused')
      }

      const seekVideo = function (seekRatio) {
        video.currentTime = video.duration * seekRatio
      }

      const updateProgressBar = function () {
        progressBar.style.left = `calc(${(seekRatio - frontTrimmedRatio) * 100}% + var(--x-1))`
      }

      const updateProgressBarWidth = function () {
        progressBar.style.left = `${frontTrimmedRatio * 100}%`
        progressBar.style.right = `${(1 - endTrimmedRatio) * 100}%`
      }

      const updateSeekableStart = function (start) {
        player.start = video.duration * frontTrimmedRatio
        updateProgressBar()
      }

      const updateSeekableEnd = function (event) {
        player.end = video.duration * endTrimmedRatio
        seekVideo(frontTrimmedRatio)
      }

      const playheadMove = function (event) {
        const seekAmount = (event.clientX - playbackRect.left) / playbackRect.width
        seekVideo(seekAmount)
        window.addEventListener('mouseup', playheadDrop)
      }

      var playheadDrop = function () {
        window.removeEventListener('mousemove', playheadMove)
        window.removeEventListener('mouseup', playheadDrop)
      }

      class dualRangeSlider {
        constructor(rangeElement) {
          this.range = rangeElement
          this.min = Number(rangeElement.dataset.min)
          this.max = Number(rangeElement.dataset.max)
          this.handles = [...this.range.querySelectorAll('.handle')]
          this.startPos = 0
          this.activeHandle

          this.handles.forEach(handle => {
            handle.addEventListener('mousedown', this.startMove.bind(this))
            handle.addEventListener('touchstart', this.startMoveTouch.bind(this))
          })

          window.addEventListener('mouseup', this.stopMove.bind(this))
          window.addEventListener('touchend', this.stopMove.bind(this))
          window.addEventListener('touchcancel', this.stopMove.bind(this))
          window.addEventListener('touchleave', this.stopMove.bind(this))

          const rangeRect = this.range.getBoundingClientRect()
          const handleRect = this.handles[0].getBoundingClientRect()
          this.range.style.setProperty('--x-1', '0px')
          this.range.style.setProperty('--x-2', `${rangeRect.width - handleRect.width / 2}px`)
          this.range.closest('.playback').style.setProperty('--x-1', '0px')
          this.range.closest('.playback').style.setProperty('--x-2', `${this.range.closest('.playback').clientWidth}px`)
          this.handles[0].dataset.value = this.range.dataset.min
          this.handles[1].dataset.value = this.range.dataset.max
        }

        removeEvents(){
          window.removeEventListener('mouseup', this.stopMove.bind(this))
          window.removeEventListener('touchend', this.stopMove.bind(this))
          window.removeEventListener('touchcancel', this.stopMove.bind(this))
          window.removeEventListener('touchleave', this.stopMove.bind(this))
        }

        startMoveTouch(e) {
          const handleRect = e.target.getBoundingClientRect()
          this.startPos = e.touches[0].clientX - handleRect.x
          this.activeHandle = e.target
          this.moveTouchListener = this.moveTouch.bind(this)
          window.addEventListener('touchmove', this.moveTouchListener)
        }

        startMove(e) {
          this.startPos = e.offsetX
          this.activeHandle = e.target
          this.moveListener = this.move.bind(this)
          window.addEventListener('mousemove', this.moveListener)
        }

        moveTouch(e) {
          this.move({ clientX: e.touches[0].clientX })
        }

        move(e) {
          const isLeft = this.activeHandle.classList.contains('left')
          const property = isLeft ? '--x-1' : '--x-2'
          const parentRect = this.range.getBoundingClientRect()
          const handleRect = this.activeHandle.getBoundingClientRect()
          let newX = e.clientX - parentRect.x - this.startPos
          if (isLeft) {
            const otherX = parseInt(this.range.style.getPropertyValue('--x-2'))
            newX = Math.min(newX, otherX - handleRect.width)
            newX = Math.max(newX, 0 - handleRect.width / 2)
          } else {
            const otherX = parseInt(this.range.style.getPropertyValue('--x-1'))
            newX = Math.max(newX, otherX + handleRect.width)
            newX = Math.min(newX, parentRect.width - handleRect.width / 2)
          }

          const value = this.calcHandleValue((newX + handleRect.width / 2) / parentRect.width)
          this.activeHandle.dataset.value = value
          this.range.style.setProperty(property, `${newX}px`)
          this.range.closest('.playback').style.setProperty(property, `${newX}px`)

          if (this.activeHandle.classList.contains('left')) {
            frontTrimmedRatio = (newX + handleRect.width / 2) / parentRect.width
            updateSeekableStart()
            updateProgressBarWidth()
            seekVideo(frontTrimmedRatio)
          } else {
            endTrimmedRatio = (newX + handleRect.width / 2) / parentRect.width
            seekVideo(endTrimmedRatio)
            updateSeekableEnd()
          }
        }

        calcHandleValue(percentage) {
          return Math.round(percentage * (this.max - this.min) + this.min)
        }

        stopMove() {
          window.removeEventListener('mousemove', this.moveListener)
          window.removeEventListener('touchmove', this.moveTouchListener)
        }
      }

      this.sliders = new dualRangeSlider(this.$el.querySelector('.dual-range'))

      const seekableBar = this.$el.querySelector('.playback__pin')
      seekableBar.addEventListener('mousedown', event => {
        pauseVideo()
        playbackRect = playbackBar.getBoundingClientRect()
        const seekAmount = (event.clientX - playbackRect.left) / playbackRect.width
        seekVideo(seekAmount)
        window.addEventListener('mousemove', playheadMove)
        playVideo()
      })

      playbackBar.addEventListener('click', event => {
        if (event.target.closest('.handle') || event.target.closest('.playback__pin')) return
        playbackRect = playbackBar.getBoundingClientRect()
        const seekAmount = (event.clientX - playbackRect.left) / playbackRect.width
        seekVideo(seekAmount)
      })

      video.addEventListener('click', ()=>{
        if(video.paused) return playVideo()
        pauseVideo()
      })

      video.addEventListener('loadeddata', () => {
        player.end = video.duration
      })

      const currentTime = this.$el.querySelector('.current-time')
      const totalTime = this.$el.querySelector('.total-time')

      const toMinutes = time => {
        const minutes = Math.floor(time / 60)
        const seconds = Math.round(time - minutes * 60)
        return `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
      }

      video.addEventListener('timeupdate', () => {
        currentTime.innerText = toMinutes(video.currentTime)
        totalTime.innerText = toMinutes(video.duration)
        seekRatio = video.currentTime / video.duration
        updateProgressBar()
        if (video.currentTime >= player.end) {
          pauseVideo()
        }
      })

      player.controls.play.addEventListener('click', ()=>{
        if(video.paused) return playVideo()
        pauseVideo()
      })

      player.controls.seekToStart.addEventListener('click', () => {
        seekVideo(player.start / video.duration)
      })

      player.controls.seekToEnd.addEventListener('click', () => {
        seekVideo(player.end / video.duration)
      })

      this.keyDownCallback = event => {
        if (event.key === " ") {
          if(video.paused) return playVideo()
          pauseVideo()
        } else if(event.key == "ArrowRight"){
          video.currentTime = Math.min(video.currentTime + 5, video.duration)
        } else if(event.key == "ArrowLeft"){
          video.currentTime = Math.max(video.currentTime - 5, 0)
        }
      }

      window.addEventListener('keydown', this.keyDownCallback)

      if(video.paused) playVideo()
    }, 50)
  },
  methods: {
    getTimestamps(){
      let handles = this.sliders.handles
      return {
        start: handles[0].dataset.value,
        end: handles[1].dataset.value
      }
    },
    async clipit(){
      this.generating = true
      let { start, end } = this.getTimestamps()
      let result = await this.createClip(this.stream, document.getElementById('basicInput').value, start, end)
      this.clipURL = result.media
      this.clipLink = result.link
      this.done = true
      this.clipPlayer = document.getElementById('clipPlayer')
      this.clipPlayer.plyr.muted = false
      // setTimeout(()=>{
      
      // }, 300)
    },
    removeEventListeners(){
      window.removeEventListener('keydown', this.keyDownCallback)
      this.sliders.removeEvents()
    }
  },
  destroyed(){
    this.removeEventListeners()
  }
}
</script>


<style>
  .center {
    margin: auto;
    width: 50%;
  }
</style>
<style scoped>
   *:active, *:focus {
outline: none;
}
 body {
background: none;
}
 .volumeSlider {
width: 20px !important;
max-width: 20px !important;
}
 @media only screen and (max-width: 768px) {
/* For mobile phones: */
 .reset__holder {
 display: none;
}
 .player-controls {
 margin-left: 60px;
}
}
 range-slider:before {
 background: linear-gradient(45deg, rgba(187, 55, 120, 1) 50%, rgba(252, 53, 55, 1) 100%) !important;
}
 .thumb {
 background: linear-gradient(45deg, rgba(187, 55, 120, 1) 50%, rgba(252, 53, 55, 1) 100%) !important;
 user-select: none;
}
 .wrapper {
width: calc(100% + 2.8rem);
height: calc(100% + 1.6rem);
margin: -0.8rem -1.4rem;
background: #161d31;
padding: 1rem;
border-radius: 0px 0px 20px 20px;
/* position: absolute; */
/* top: 50%; */
/* left: 50%; */
/* transform: translate(-50%, -50%); */

}
 .video {
width: 100%;
cursor: pointer;
}

.clipButton {
    border: none;
    background: transparent;
    color: white;
    cursor: pointer;
    padding: 0;
    margin: 0;
}

.video__wrapper {
    position: relative;
}

.controls {
    position: absolute;
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.5rem 1rem;
    background-color: rgba(0,0,0,0);
    border-radius: 0.5rem;
    backdrop-filter: blur(0px);
    transition: all 0.2s;
}

.video__wrapper:hover .controls,
.video__wrapper.non-init .controls {
    background-color: rgba(0,0,0,.5);
    backdrop-filter: blur(12px);
}

.video__wrapper.init .play-control,
.video__wrapper.init .seek-start,
.video__wrapper.init .seek-end {
    opacity: 0;
    transition: all 0.2s;
}

.video__wrapper:hover .play-control,
.video__wrapper:hover .seek-start,
.video__wrapper:hover .seek-end {
    opacity: 1;
}

.video__wrapper:hover .time {
    background-color: rgba(0,0,0,0);
}

.play-control {
    margin-right: 0.25rem;
}

.play-control svg {
    height: 2rem;
}

.play-control.pause .svg--play {
    display: none;
}

.play-control.play .svg--pause {
    display: none;
}

.seek-start, .seek-end {
    opacity: 0.7;
    transition: all 0.2s;
    padding: 0;
    margin-top: 0.2rem;
}

.seek-start svg, .seek-end svg {
    height: 1.4rem;
}

.seek-start:hover, .seek-end:hover {
    opacity: 1;
}

.time {
    display: flex;
    transition: all 0.2s;
    padding: 0.25rem;
    border-radius: .3rem;
    align-items: center;
}

.video__wrapper.init .time {
    background: rgba(0,0,0,.5);
}

.total-time, .current-time, .time-divider {
    color: white;
}

.current-time {
    margin-left: .25rem;
}

.total-time {
    font-size: 0.8rem;
    opacity: .7;
    margin-right: 0.25rem;
}

.time-divider {
    margin: 0 0.25rem;
}

.playback {
    position: relative;
    width: 100%;
    height: 40px;
    margin-top: 1rem;
    border-radius: 0.4rem;
    background: #161a1f;
    user-select: none;
}

.playback__pin {
    cursor: grabbing;
    position: absolute;
    height: calc(100% + 4px);
    width: 3px;
    background: white;
    left: 0px;
    top: -2;
    z-index: 10;
    border-radius: 2px;
    pointer-events: none;
}

.playback__pin::after {
    content: '';
    height: 10px;
    width: 10px;
    background: white;
    position: absolute;
    top: -8px;
    left: -3px;
    border-radius: 10px;
    pointer-events: initial;
}

.playback__thumbs {
    border-radius: 0.2rem;
    overflow: hidden;
    height: 100%;
    display: flex;
    position: absolute;
    user-select: none;
    left: 0;
    top: 0;
    width: 100%;
}

.playback__thumbs::after {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    backdrop-filter: brightness(0.4);
    left: 0;
    top: 0;
}

.playback__thumbs img {
    max-height: 100%;
}

 .dual-range {
    --range-size: 40px;
    --range-width: 100%;
    --handle-size: 1.3;
    height: 100%;
    width: 100%;
    background: transparent;
    border-radius: 50px;
    position: relative;
    user-select: none;
     z-index: 1;
}
 .dual-range .highlight {
    position: absolute;
    height: 100%;
    width: calc(calc(var(--x-2) - var(--x-1)));
    left: var(--x-1);
     backdrop-filter: brightness(1.7);
    z-index: 1;
    user-select: none;
}
 .dual-range .handle {
 width: 8px;
 height: calc(100% + 6px);
     top: -3px;
 background: #7367f0;
 position: absolute;
 box-shadow: var(--shadow);
 z-index: 2;
 cursor: grab;
}

.handle.left {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
}

.handle.right {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
}

 .dual-range .handle:active {
 cursor: grabbing;
}
 .dual-range .handle.left {
 left: var(--x-1);
}
 .dual-range .handle.right {
 left: var(--x-2);
}
</style>
