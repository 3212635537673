<!-- eslint-disable -->
<template>
  <div v-if="!hide" class="commands">
      <div @click="sendCommand(_i)" :class="_i == index && 'focused'" class="command" v-bind:key="command.key" v-for="(command, _i) in commands.filter(c=>c.key.includes(message))">
        {{command.key}} {{command.syntax}}
      </div>
  </div>
</template>

<style scoped>
.commands {
  position: absolute;
  bottom: 100%;
  margin-bottom: 1rem;
  background: #283046;
  width: 100%;
  left: 0;
  padding: 0 .5rem;
  border-radius: .25rem
}

.command {
  padding: .5rem;
  margin-bottom: .25rem;
  border-radius: .25rem;
  transition: all .15s
}

.command:first-child {
  margin-top: .5rem
}

.command:last-child {
  margin-bottom: .5rem
}

.focused, .command:hover {
  background: #111727;
  cursor: pointer;
  color: white
}
</style>

<script>
/* eslint-disable */

export default {
  data() {
    return {
        commands: [
          {key: '/mod', syntax: '<username>'},
          {key: '/vip', syntax: '<username>'},
          {key: '/ban', syntax: '<username>'},
          {key: '/clear', syntax: '(Clears the chat)'},
        ],
        index: -1,
        hide: false
    }
  },
  mounted() {
    this.cb = (e)=>{
      if(e.key == 'Enter') return

      this.hide = e.key == 'Escape'
      if(this.hide) return this.index = -1
      
      if(!['ArrowUp', 'ArrowDown', ].includes(e.key)) return
      
      e.preventDefault()
      
      const MAX = this.commands.filter(c=>c.key.includes(this.message)).length - 1
      
      e.key == 'ArrowDown' ? ++this.index : --this.index
      if(this.index > MAX){
        this.index = 0
      } else if (this.index < 0){
        this.index = MAX
      }

      return false
    }

    this.scb = (e)=>{
      if(e.key == 'Enter' && !this.hide && this.index != -1){
        e.preventDefault()
        this.sendCommand()
      }
    }

    document.addEventListener('keydown', this.cb)
    document.addEventListener('keydown', this.scb)
  },
  destroyed(){
    document.removeEventListener('keydown', this.cb)
    document.removeEventListener('keydown', this.scb)
  },
  props: {
    message: {
        required: true,
        default: '',
    },
  },
  methods: {
    sendCommand(i = this.index){
      this.$emit('command', this.commands.filter(c=>c.key.includes(this.message))[i].key + ' ')
    }
  },
  watch: {
    message: function (nv, ov){
      const MAX = this.commands.filter(c=>c.key.includes((nv || ''))).length - 1
      if(this.index > MAX) this.index = MAX
    },
  }
}
</script>
