<!-- eslint-disable -->
<template>
    <!--  -->
  <form
    @submit.prevent="send"
    class="input"
    :class="[this.focused ? 'focused' : '']"
  >
    <chat-commands @command="setCommand" v-if="content.startsWith('/') && !content.includes(' ') && (isMod || isOwner)" :message="content"></chat-commands>
    <input
      @focus="focus"
      @blur="blur"
      class="chat__input"
      v-bind:value="content"
      @input="updateContent"
      type="text"
      placeholder="Send a message"
    />
    <emoji-picker></emoji-picker>
    <gif-picker @sendGif="sendGif"></gif-picker>
    <button type="submit" class="chat__send">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M20.34,9.32l-14-7a3,3,0,0,0-4.08,3.9l2.4,5.37h0a1.06,1.06,0,0,1,0,.82l-2.4,5.37A3,3,0,0,0,5,22a3.14,3.14,0,0,0,1.35-.32l14-7a3,3,0,0,0,0-5.36Zm-.89,3.57-14,7a1,1,0,0,1-1.35-1.3l2.39-5.37A2,2,0,0,0,6.57,13h6.89a1,1,0,0,0,0-2H6.57a2,2,0,0,0-.08-.22L4.1,5.41a1,1,0,0,1,1.35-1.3l14,7a1,1,0,0,1,0,1.78Z"
        />
      </svg>
    </button>
  </form>
</template>

<style scoped>
.input.focused {
  border-color: #7367f0;
}

.chat__input {
  padding: 0.5rem 0.25rem;
  background: transparent;
  flex: 1;
  border: none;
  min-width: 0;
  color: white;
}

.chat__input:focus {
  outline: none;
}

.chat__send {
  padding: 0.25rem 0.5rem;
  font-size: 0.9rem;
  border-radius: 0.25rem;
  color: white;
  background: transparent;
  border: none;
  background: #7367f0;
  height: 35px;
  width: 35px;
  margin-left: 0.25rem;
  transition: all 0.2s;
}

.chat__send:hover {
  transform: scale(1.05);
  background: #5d52d3;
}

.chat__send:active {
  transform: scale(0.95);
  background: #443aac;
}

.chat__send svg {
  height: 1.5rem;
}
</style>

<script>
/* eslint-disable */
import { EmojiButton } from '@joeattardi/emoji-button'
import EmojiPicker from './emoji-picker.vue'
import GifPicker from './gif-picker.vue'
import ChatCommands from './chat-commands.vue'

export default {
  data() {
    return {
      content: '',
      focused: false,
    }
  },
  mounted() {
    this.initEmoji()
  },
  props: {
     isMod: {
      type: Boolean,
      default: false,
    },
    isOwner: {
      type: Boolean,
      default: false,
    },
    mentioned: {
      default: false,
    },
  },
  watch: {
    mentioned: function(nv, ov) {
      if (!nv) return
      const mention = `@${nv} `
      this.$emit('resetMention')
      if (this.content.includes(mention.trim())) return
      if (this.content.trim() == '') return (this.content = mention)
      this.content = this.content.trim() + ` ${mention}`
    },
  },
  methods: {
    test() {
    },
    focus() {
      this.focused = true
    },
    blur() {
      this.focused = false
    },
    updateContent() {
      this.content = document.querySelector('.chat__input').value
    },
    setCommand(command){
      this.content = command
      this.$el.querySelector('.chat__input').focus()
    },
    send() {
      if (!this.content || !this.content.trim()) return
      this.$emit('send', {
        content: this.content,
      })
      this.content = ''
    },
    sendGif(id) {
      this.$emit('sendGif', id)
    },
    initEmoji() {
      const picker = new EmojiButton(
        {
          initialCategory: 'custom',
          styleProperties: {
            '--font': 'Courier New',
            '--background-color': '#111727',
            '--text-color': 'white',
            '--category-button-color': 'linear-gradient(90deg,#833ab4,#fd1d1d)',
          },
          i18n: {
            search: 'Search emojis...',
            categories: {
              custom: 'Brime Global',
            },
            notFound: 'No emojis found',
          },
          custom: [
            {
              name: 'BrimeTime ',
              emoji:
                'https://content.brimecdn.com/brime/emote/607bb7a0d2595193fc085ff9/1x',
            },
            {
              name: 'Doge ',
              emoji:
                'https://content.brimecdn.com/brime/emote/607bb07bd2595193fc085ff6/1x',
            },
            {
              name: 'brimePop ',
              emoji:
                'https://content.brimecdn.com/brime/emote/60adf0acb02edcd85e096079/1x',
            },
            {
              name: 'Nashed ',
              emoji:
                'https://content.brimecdn.com/brime/emote/607f931013b66ed6d03de8cc/1x',
            },
            {
              name: 'OBSSweat ',
              emoji:
                'https://content.brimecdn.com/brime/emote/6098e55d791971b70b1a3d05/1x',
            },
            {
              name: 'brimeDoge ',
              emoji:
                'https://content.brimecdn.com/brime/emote/60aeb56847e3a5efb5d862b9/1x',
            },
            {
              name: 'Pog ',
              emoji:
                'https://content.brimecdn.com/brime/emote/607ba48ad2595193fc085ff4/1x',
            },
            {
              name: 'KEKW ',
              emoji:
                'https://content.brimecdn.com/brime/emote/607ba5dcd2595193fc085ff5/1x',
            },
            {
              name: 'FeelsBrimeProMan ',
              emoji:
                'https://content.brimecdn.com/brime/emote/607fa67d13b66ed6d03de93e/1x',
            },
            {
              name: 'PauseChamp ',
              emoji:
                'https://content.brimecdn.com/brime/emote/607bb721d2595193fc085ff8/1x',
            },
            {
              name: 'Jebaited ',
              emoji:
                'https://content.brimecdn.com/brime/emote/607bb52fd2595193fc085ff7/1x',
            },
            {
              name: 'monkaW ',
              emoji:
                'https://content.brimecdn.com/brime/emote/607bb833d2595193fc085ffa/1x',
            },
            {
              name: 'YEP ',
              emoji:
                'https://content.brimecdn.com/brime/emote/608c9405e2e12599035c6f61/1x',
            },
          ],
        },
        {
          autoHide: true,
        },
      )

      picker.on('emoji', selection => {
        if (selection.url) {
          this.content = this.content.trim() + ` ${selection.name}`
        } else {
          this.content = this.content.trim() + ` ${selection.emoji}`
        }
        this.$el.querySelector('.chat__input').focus()
      })

      const toggle = document.querySelector('.input__emoji')
      toggle.addEventListener('click', () => picker.togglePicker(toggle))
    },
  },
  components: {
    EmojiPicker,
    GifPicker,
    ChatCommands,
  },
}
</script>
