<template>
  <div class="squad">
    <div class="channel_1">
      <player
        :id="`channel_1`"
        :channel="channel1"
      />
      <chat :channel="channel1" class="chat1" :id="`channel_1_chat`"/>
    </div>
    <div class="channel_2">
      <player
        :id="`channel_2`"
        :channel="channel2"
      />
      <chat :channel="channel2" class="chat2" :id="`channel_2_chat`"/>
    </div>
  </div>
</template>

<script>
import Player from '@/components/global/LLPlayer.vue'
import chat from '@/components/chat/chat.vue'

export default {
  components: {
    Player,
    chat,
  },
  props: ['channel1', 'channel2'],
}
</script>

<style>
.channel_1 {
width: 50%;
display:inline-block;
}
.channel_2 {
width: 50%;
display:inline-block;
}
.chat1 {
left: 0 !important;
width: calc(50%) !important;
height: 39% !important;
}
.chat2 {
left: 50% !important;
width: calc(50%) !important;
height: 39% !important;
}
</style>
