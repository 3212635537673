<!-- eslint-disable -->
<template>
    <img
        @load="$emit('load')"
        class="gif__element"
        ref="gif"
        title="GIF powered by Tenor"
        :class="[picker ? 'picker' : 'gif--message']"
        @click="picker ? $emit('sendGif', gif.id) : null"
        @mouseleave="endGif" @mouseenter="startGif" :data-gif="gif.src" :data-static="gif.preview" :src="gif.preview"
    />
</template>

<style scoped>
img.picker {
    width: 100%;
    cursor: pointer;
    margin-bottom: 0.5rem;
}

img.gif--message {
    display: block;
    margin: 0.25rem 0;
    max-height: 200px;
    width: auto;
    max-width: 200px;
}
</style>

<script>
/* eslint-disable */
export default {
    data(){
        return {
            
        }
    },
    mounted(){
        if(this.$el.dataset.visible != 'false' && document.hasFocus()) return this.startGif()
    },
    methods:{
        startGif(){
            let t = this.$el
            if(t.src == t.dataset.gif) return
            t.src = t.dataset.gif
        },
        endGif(){
            if(this.$el.dataset.visible != 'false' && document.hasFocus()) return
            let t = this.$el
            t.src = t.dataset.static
        }
    },
    props: {
        gif: {
            required: true,
            type: Object    
        },
        picker: {
            default: false,
            type: Boolean
        }
    }
}
</script>