<!-- eslint-disable -->
<template>
  <div class="user__card">
    <div class="user__card__wrapper" v-if="user_info">
      <a
        :href="`https://brime.tv/${user_info.username}`"
        class="avatar"
        target="_blank"
      >
        <img
          v-if="user_info.username"
          :src="`https://assets.brimecdn.com/cdn-cgi/image/width=100,quality=100/users/${user_info.xid}/avatar`"
          onerror="this.onerror=null;this.src='https://content.brimecdn.com/brime/assets/default-profile-picture.png'"
        />
      </a>
      <div class="user__info">
        <a
          :href="`https://brime.tv/${user_info.username}`"
          target="_blank"
          :style="`--color: ${user_info.color || 'red'}`"
          class="username"
          >{{ user_info.display_name }}</a
        >
        <div class="badges">
          <img v-for="badge in user_info.badges" :key="badge" :src="badge" />
        </div>
        <a
          class="user__link"
          :href="`https://brime.tv/${user_info.username}`"
          target="_blank"
          >View Profile</a
        ><br>
        <!--<span style="margin-left: -80px;">-->
        
        <!--</span>-->
      </div>
      <span class="close" @click="user_info = false">x</span>
      
    </div>
    <div class="uContext" v-if="user_info">
    <b-button
          v-if="following === false && !isChannelOwner"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          class="newFollowButton"
          size="sm"
          @click="follow"
          style="padding: 0.45rem"
        >
          <feather-icon
            icon="HeartIcon"
            class="mr-50 pt-0 pb-0"
          />
          <span
            class="align-middle"
            style="line-height: 14px"
          >Follow</span>
        </b-button>
        <b-button
          v-if="following === true && !isChannelOwner"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          size="sm"
          title="following"
          variant="primary"
          class="btn-icon"
          @click="unfollow"
          style="padding: 0.45rem"
        >
          <feather-icon
            icon="HeartIcon"
            class="fill"
          />
        </b-button>
        <b-button
          variant="outline-primary"
            size="sm"
          class="followCount mr-1"
          disabled
          style="padding: 7px; color: #7367f0 !important;"
        >
          <span id="followCount">{{ user_info.followers }}</span> <span id="followersText">Followers</span>
        </b-button>
    </div>
  </div>
</template>

<style scoped>
.uContext {
  /* Center horizontally*/
display: flex;
  justify-content: left;
  margin-top: -40px;
  padding-left: 100px;
}
.btn-outline-primary {
border-top-right-radius: 5px !important;
border-bottom-right-radius: 5px !important;
}
.subscribe {
border-radius: 5px !important;
}
.btn-icon {
border-top-left-radius: 5px !important;
border-bottom-left-radius: 5px !important;
border-top-right-radius: 0px !important;
border-bottom-right-radius: 0px !important;
}
.newFollowButton{
border-top-left-radius: 5px !important;
border-bottom-left-radius: 5px !important;
border-top-right-radius: 0px !important;
border-bottom-right-radius: 0px !important;
}
.followCount{
    border: 1px solid #7367f0 !important;
border-top-left-radius: 0px !important;
border-bottom-left-radius: 0px !important;
}
 .dark-layout .avatar{
     background-color: unset !important;
 }
.user__card {
  color: white;
  position: fixed;
  top: 50px;
  left: 50px;
  z-index: 10;
  width: 300px;
  margin-left: -150px;
}

@media screen and (max-width: 768px) {
  .user__card {
    width: 100vw;
    left: 0px !important;
    margin: 0;
  }
}

.user__card__wrapper {
  display: flex;
  align-items: flex-start;
  background: #111727;
  border-radius: 0.75rem;
  padding: 1.5rem;
  padding-bottom: 3rem;
  box-shadow: 0px 0px 40px rgba(4, 6, 14, 0.55);
}

.avatar {
  height: 60px;
  width: 60px;
  border-radius: 8px;
  flex-shrink: 0;
}

.avatar img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.badges {
  margin-top: 0.5rem;
}

.badges img {
  height: 20px;
  width: 20px;
  margin-right: 0.5rem;
  margin-bottom: 0.25rem;
}

.user__info {
  padding-left: 1.25rem;
  padding-top: 0.25rem;
}

.user__info .username {
  font-weight: bolder;
  font-size: 1.25rem;
  line-height: 1rem;
  color: var(--color);
  padding-right: 40px;
}

.user__info .user__link {
  color: white;
  margin-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.close {
  position: absolute;
  right: 1rem;
  top: 1rem;
  font-size: 1.25rem;
  color: white;
  cursor: pointer;
}
</style>

<script>
/* eslint-disable */
import {
 BButton
 } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
    components: {
        BButton
    },
    directives: {
    Ripple,
  },
  data() {
    return {
      user_info: false,
      following: false,
      chatChannel: {},
      userChannel: {channel: {}},
      isChannelOwner: false,
    };
  },
  methods:{
async unfollow(){
      let result = await this.unfollowChannel(this.userChannel.channel.xid)
      if (result.ok == true){
        this.following = false
        this.user_info.followers = this.user_info.followers - 1
      }
    },
    async follow(){
      let result = await this.followChannel(this.userChannel.channel.xid)
      if (result.ok == true){
        this.following = true
        this.user_info.followers = this.user_info.followers + 1
      }
    },
  },
  updated() {
    this.$el.style = `left: ${Math.min(
      this.left,
      window.innerWidth - 150
    )}px; top: ${Math.min(
      this.top,
      window.innerHeight - this.$el.clientHeight
    )}px`;
  },
  watch: {
    target: async function (nv, ov) {
      this.user_info = {}
      let { element, user } = nv;
      if (!element) return;
      const self = this;
      let chatChannel = await this.channelLookupByXID(this.channel)
      this.userChannel = await this.channelLookupBySlug(user.username)
      this.chatChannel = chatChannel
      fetch(`https://api.brime.tv/v1/channels/slug/${user.username}`)
        .then((r) => r.json())
        .then(async (data) => {
          self.user_info = {
            ...user,
            badges: null,
            brime_pro: false,
            followers: data.followers,
          };
          // await 
        this.following = await this.userFollowCheck(this.userChannel.xid)
        if(this.user_info.xid == chatChannel.xid){
            this.isChannelOwner = true
        } else {
            this.isChannelOwner = false
        }
          let rect = user.element.getBoundingClientRect();
          self.left = rect.left;
          self.top = rect.bottom;
        });
    },
  },
  props: {
    target: {
      required: true,
    },
    channel: {
      required: true,
    },
  },
};
</script>
