<!-- eslint-disable -->
<template>
    <div class="i__reply">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M19.61,7.25a1,1,0,0,0-.22-.33L14.76,2.29a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l2.92,2.92h-8a4,4,0,0,0-4,4V21a1,1,0,0,0,2,0V10.63a2,2,0,0,1,2-2h8l-2.92,2.92a1,1,0,0,0,.71,1.71,1,1,0,0,0,.7-.3l4.63-4.62A1.19,1.19,0,0,0,19.61,8,1,1,0,0,0,19.61,7.25Z"/></svg>
        <span class="m__username" :style="'--color:' + (reply.user.color || 'red')">{{reply.user.displayname}}</span><span>:</span>
        <span v-html="reply.content.parsed" class="m__content">
        </span>
        <span class="remove" @click="removeReply">x</span>
    </div>
</template>

<style scoped>
.i__reply {
    background: #2f3850;
    width: calc(100% - 1rem);
    margin-left: 0.5rem;
    padding: 0.25rem 0.5rem;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    display: flex;
    align-items: center;
}

.i__reply .m__content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.remove {
    margin-left: auto;
    flex-shrink: 0;
    padding: 0.25rem 0.6rem;
    border-radius: 0.2rem;
    opacity: 0;
    transition: all 0.2s;
    cursor: pointer;
}

.remove:hover {
    background: #20283b;
}

.i__reply:hover .remove {
    opacity: 1;
}

.m__username {
    color: var(--color);
    font-weight: bolder;
}

.m__content {
    margin-left: 0.25rem;
}

.i__reply svg {
    height: 15px;
    margin-right: 0.25rem;
}
</style>

<script>
/* eslint-disable */
export default {
    data(){
        return {

        }
    },
    methods: {
        removeReply(){
            this.$emit('removeReply', '')
        }
    },
    props: {
        reply: {
            type: Object
        }
    }
}
</script>