<template>
<div :id="'m__' + message.xid" @contextmenu="(e)=>openMessageContext(e, message)" class="wrapper" :class="[active ? 'active' : '', highlight || mentioned ? 'highlighted' : '']">
    <div @click="$emit('scrollReply', message.reply.xid)" v-if="message.reply" class="reply">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M19.61,7.25a1,1,0,0,0-.22-.33L14.76,2.29a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l2.92,2.92h-8a4,4,0,0,0-4,4V21a1,1,0,0,0,2,0V10.63a2,2,0,0,1,2-2h8l-2.92,2.92a1,1,0,0,0,.71,1.71,1,1,0,0,0,.7-.3l4.63-4.62A1.19,1.19,0,0,0,19.61,8,1,1,0,0,0,19.61,7.25Z"/></svg>
        <span class="m__username" :style="'--color:' + (message.reply.user.color || 'red')">{{message.reply.user.display_name}}</span><span>:</span>
        <span class="m__content">
            {{message.reply.content.type == 'gif' ? '> Gif message' : message.reply.content.raw}}
        </span>
    </div>
        <div class="message">
        <span v-if="timestamps" class="timestamps">{{convertTime(message.timestamp) }}</span>
        <span class="dontBreak">
        <img class="platformLogo" v-if="message.content.platform == 'twitch'" src="https://assets.brimecdn.com/twitch_vector.svg" title="message from Twitch" width="17" style="margin-right: 5px;">
        <img class="platformLogo" v-if="message.content.platform == 'youtube'" src="https://assets.brimecdn.com/youtube-icon.svg" title="message from YouTube" width="20" style="margin-right: 3px; margin-top: -3px;">
        <img class="platformLogo" v-if="message.content.platform == 'trovo'" src="https://assets.brimecdn.com/Trovo-Logo.svg" title="message from Trovo" width="15" style="margin-right: 5px; margin-top: -2px;">
        <img class="userBadge" v-if="message.user.is_bot" src="https://assets.brimecdn.com/bot_badge.svg" title="Bot Account" width="20" style="margin-right: 3px; margin-top: -3px;">
        <img class="userBadge" v-if="message.user.is_broadcaster" src="https://assets.brimecdn.com/broadcaster_badge.svg" title="Broadcaster" width="18" style="margin-right: 3px; margin-top: -1px">
        <img class="userBadge" v-if="message.user.is_mod && !message.user.is_broadcaster" src="https://assets.brimecdn.com/mod_badge.svg" title="Channel Mod" width="18" style="margin-right: 3px; margin-top: -1px">
        <img class="userBadge" v-if="message.user.is_staff" src="https://assets.brimecdn.com/brime_staff.svg" title="Staff" width="18" style="margin-right: 3px; margin-top: -1px">
        <img class="userBadge" v-if="message.user.is_vip" src="https://assets.brimecdn.com/vip_badge.svg" title="VIP" width="18" style="margin-right: 3px; margin-top: -1px">
        <img class="userBadge" v-if="message.user.is_verified" src="https://assets.brimecdn.com/brime_verified.svg" title="verified" width="18" style="margin-right: 3px; margin-top: -1px">

        <img v-if="message.content.platform == 'twitch' && message.content.subscriber" src="https://id1o3gz7dylz.compat.objectstorage.us-ashburn-1.oraclecloud.com/brime/share/2021/09/twitch_sub.svg" title="subscribed on Twitch" width="15" style="margin-right: 5px;">
        <span v-if="message.content.platform == 'twitch'" @click="openTwitchUser(message.user.displayname)" class="m__username" :style="'--color:' + (message.user.color || 'red')">{{message.user.displayname}}</span>
        <span v-if="message.content.platform == 'youtube'" @click="openYouTubeUser(message.user.channelURL)" class="m__username" :style="'--color:' + (message.user.color || 'red')">{{message.user.displayname}}</span>
        <span v-if="message.content.platform == 'trovo'" @click="openTrovoUser(message.user.displayname)" class="m__username" :style="'--color:' + (message.user.color || 'red')">{{message.user.displayname}}</span>
        <span v-if="['twitch', 'trovo', 'youtube'].indexOf(message.content.platform) == -1" @contextmenu="(e)=>openUserContext(e, message.user)" class="m__username" :style="'--color:' + (message.user.chat_color || 'red')" @click="showUser()">{{message.user.display_name}}</span><span>:</span>
    </span>
        <span class="m__content">
            <span v-if="message.content.type != 'gif'" v-html="message.content.parsed"></span>
        </span>
        <gif
            @load="scroll"
            v-if="message.content.type == 'gif'"
            :gif="message.content.meta.attachements[0]"
            :key="message.content.meta.attachements[0].id"
        />
    </div>
    <button class="m__reply" @click="setReplyTarget">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M19.61,7.25a1,1,0,0,0-.22-.33L14.76,2.29a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l2.92,2.92h-8a4,4,0,0,0-4,4V21a1,1,0,0,0,2,0V10.63a2,2,0,0,1,2-2h8l-2.92,2.92a1,1,0,0,0,.71,1.71,1,1,0,0,0,.7-.3l4.63-4.62A1.19,1.19,0,0,0,19.61,8,1,1,0,0,0,19.61,7.25Z"/></svg>
    </button>
    <button class="m__mention" @click="mention">
        @
    </button>
</div>
</template>

<style>
.timestamps{
    font-variant-numeric: tabular-nums; 
    align-items: center; 
    color: rgb(180, 183, 189, 0.5); font-size: 12px;
}
.m__content__mention {
    background: rgba(119, 107, 255, .45);
    padding: 0.15rem 0.25rem;
    cursor: pointer;
    transition: background-color 0.2s;
}

.m__content__mention:hover {
    background: #7367f0;
    color: white;
}

.m__emote {
    height: 2em;
}
</style>

<style scoped>
.dontBreak {
    word-break: unset;
    white-space: nowrap;
}
.wrapper {
    transition: all 0.2s;
    padding: 0.25rem 0.5rem;
    margin: 0.5rem 0;
    position: relative;
    border-radius: 0.2rem;
}

.wrapper:hover .m__reply, .wrapper:hover .m__mention {
    display: block;
}

.m__username {
    font-weight: bolder;
    color: var(--color);
    cursor: pointer
}

.m__reply, .m__mention {
    position: absolute;
    right: 4px;
    top: -10px;
    background: transparent;
    color: white;
    transition: background-color 0.2s;
    background-color: #283046;
    display: none;
    border: none;
    border-radius: 0.25rem;
    padding: 0.25rem;
    height: 30px;
    width: 30px;
}

.m__mention {
    right: 42px;
}

.m__content {
    margin-left: 0.2rem;
    word-break: break-word;
}

.m__reply svg {
    height: 70%;
}

.m__reply:hover, .m__mention:hover {
    background-color: #7367f0;
}

.wrapper:hover, .wrapper.active {
    background: rgba(255, 255, 255, 0.06);
}

.wrapper.highlighted {
    background: rgba(255, 255, 255, 0.08);
}

.reply {
    padding-bottom: 0.5rem;
    margin-left: 0.1rem;
    position: relative;
    font-style: italic;
    font-size: 0.9rem;
    cursor: pointer;
}

.reply svg {
    height: 1rem;
    margin-right: 0.1rem;
}

</style>

<script>

import gif from './gif.vue'
/* eslint-disable */
/**
 * String.prototype.replaceAll() polyfill
 * https://gomakethings.com/how-to-replace-a-section-of-a-string-with-another-one-with-vanilla-js/
 * @author Chris Ferdinandi
 * @license MIT
 */
if (!String.prototype.replaceAll) {
	String.prototype.replaceAll = function(str, newStr){

		// If a regex pattern
		if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
			return this.replace(str, newStr);
		}

		// If a string
		return this.replace(new RegExp(str, 'g'), newStr);

	};
}
export default {
  components: { gif },
    data(){
        return {
            is_broadcaster: false,
            is_moderator: false,
        }
    },
    created(){
        this.chat = document.querySelector('.messages')
             if(this.message.content.type != 'gif'){
            let parsed = this.replaceURL(this.message.content.parsed)
            parsed = this.replaceMentions(parsed)
            this.message.content.parsed = this.replaceEmotes(parsed)
        }
    },
    mounted(){
        this.scroll()
    },
    methods: {
        convertTime(timestamp){
            var myDate = new Date(timestamp);
            const string = myDate.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })
            return string.replace("AM","").replace("PM","")
        },
        openTwitchUser(username){
            window.open('https://twitch.tv/' + username)
        },
        openYouTubeUser(username){
            window.open('https://youtube.com/channel/' + username)
        },
        openTrovoUser(username){
            window.open('https://trovo.live/' + username)
        },
        openUserContext(e, user){
            e.preventDefault()
            this.$emit('context', {
                target: e.target,
                x: e.clientX,
                y: e.clientY,
                data: user,
                type: 'user'
            })
        },
        openMessageContext(e, message){
            let {target} = e
            if(target.closest('.m__username')) return
            e.preventDefault()
            this.$emit('context', {
                target,
                x: e.clientX,
                y: e.clientY,
                data: message,
                type: 'message'
            })
        },
        showUser(e, id = false, el){
            let user = {...this.message.user, element: this.$el.querySelector('.message .m__username')}
            if(id){
                let u = this.message.content.meta.mentions.find(e=>e.legacy_id == id)
                if(u) user = {...u, element: el}
            }
            this.$emit('showUser', {
                element: this.$el,
                user: user || this.message.user
            })
        },
        showLink(e, url = false, element){
            this.$emit('showLink', {
                element,
                url
            })
        },
        escapeRegex(str){
            return str.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1");
        },
        escapeHTML(str){
            return str.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&#039;")
        },
        replaceURL(p){
            let links = this.message.content.meta.links
            for(let link of links){
                p = p.replaceAll(`<link:${link.xid}>`, `<a target="_blank" onclick="((e)=>{e.preventDefault(); e.stopImmediatePropagation(); e.target.closest('.wrapper').__vue__.showLink(null, '${link.href}', e.target)})(event)" href="${link.href}">${link.match}</a>`)
            }
            p = p.replaceAll(`<link:blocked>`, '[blocked URL]')
            return p
        },
        replaceMentions(p){
            for(let mention of this.message.content.meta.mentions){
                let {displayname, legacy_id, xid} = mention
                p = p.replaceAll(`<mention:${xid}>`, `<span class="m__content__mention" onclick="((e)=>{e.target.closest('.wrapper').__vue__.showUser(null, '${legacy_id}', e.target)})(event)">${displayname}</span>`)
            }
            return p
        },
        replaceEmotes(p){
            for(let emote of this.message.content.meta.emotes){
                let {xid, src, code} = emote
                p = p.replaceAll(`<emote:${xid}>`, `<img title="${code}" alt="${code}" src="${src}" class="m__emote">`)
            }
            return p
        },
        scroll(){
            if(this.chat.dataset.pauseScrolling === "true") return
            this.chat.scrollTop = this.chat.scrollHeight + this.chat.offsetHeight + 64
        },
        setReplyTarget(){
            this.$emit('reply', this.message)
        },
        mention(){
            this.$emit('mention', this.message.user.display_name)
        }
    },
    props: {
        timestamps: {
            type: Boolean,
            required: false,
            default: false
        },
        message: {
            type: Object,
            required: true,
            default: false
        },
        active: {
            type: Boolean,
            default: false
        },
        highlight: {
            default: false,
            type: Boolean
        },
        mentioned: {
            default: false,
            type: Boolean
        }
    }
}
</script>
