<!-- eslint-disable -->
<!--
eslint almost as disabled as GEEKEN LMAO!!!

also props: context(event, data) & isMod (bool)
methods:
- sendCommand(command, data)
- v-if="isMod" for elevated commands
- mention && reply just wrappers
-->
<template>
  <div v-if="context">
    <div class="commands"  v-if="context.type == 'user'">
    <!--GOES AFTER CLASS  @click="()=>sendCommand('/test')" -->
      <a target="_blank" :href="`https://brime.tv/${context.data.username}`" class="command">
        <!-- <span class="icon"></span> -->
        <!-- 
          context.data represents a USER object!
        -->
        <span class="text">
          Visit {{context.data.display_name}}'s profile
        </span>
      </a>
      <span class="command" @click="()=>mention(context.data.display_name)">
        <!-- <span class="icon"></span> -->
        <span class="text">
          Mention @{{context.data.display_name}}
        </span>
      </span>
      <span v-if="isMod || isOwner" class="command" @click="()=>sendCommand('/timeoutplaceholder', context.data.xid)">
        <!-- <span class="icon"></span> -->
        <span class="text">
          Timeout {{context.data.display_name}} (5m)
        </span>
      </span>
      <span v-if="isMod || isOwner" class="command" @click="()=>sendCommand('/mod', context.data.username)">
        <!-- <span class="icon"></span> -->
        <span class="text">
          Mod {{context.data.display_name}}
        </span>
      </span>
      <span v-if="isMod || isOwner" class="command" @click="()=>sendCommand('/vip', context.data.username)">
        <!-- <span class="icon"></span> -->
        <span class="text">
          VIP {{context.data.display_name}}
        </span>
      </span>
      <span v-if="isMod || isOwner" class="command destructive" @click="()=>sendCommand('/ban', context.data.xid)">
        <!-- <span class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg>
        </span> -->
        <span class="text">
          Ban {{context.data.display_name}}
        </span>
      </span>
    </div>
    <div class="commands"  v-if="context.type == 'message'">
      <span class="command" @click="()=>reply(context.data)">
        <!-- <span class="icon"></span> -->
        <span class="text">
          Reply
        </span>
      </span>
      <span class="command" @click="()=>mention(context.data.user.displayname)">
        <!-- <span class="icon"></span> -->
        <span class="text">
          Mention @{{context.data.user.displayname}}
        </span>
      </span>
      <span v-if="isMod || isOwner" class="command destructive">
        <!-- <span class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg>
        </span> -->
        <!-- 
          context.data represents a MESSAGE object!
        -->
        <span class="text" @click="()=>sendCommand('/delete', context.data.xid)">
          Delete message
        </span>
      </span>
    </div>
  </div>
</template>

<style scoped>
.commands {
  position: fixed;
  background: #283046;
  padding: .5rem;
  border-radius: .25rem;
  box-shadow: 5px 5px 10px rgba(0,0,0,.16);
}

.command {
  padding: .5rem .75rem;
  border-radius: .25rem;
  font-size: .95em;
  cursor: pointer;
  transition: all .15s;
  display: flex;
  color: inherit;
  align-items: center;
}

.command:not(:last-child) {
  margin-bottom: .2rem
}

.command.destructive:hover {
  background: rgb(206, 6, 6);
  color: white;
}

.command.destructive {
  color: rgb(253, 0, 0);
  font-weight: bolder;
  font-size: .9em
}

.command .icon {
  height: 1.25em;
  width: 1.25em;
  margin-right: .2rem;
  text-align: center;
}

.command .icon svg {
  height: 100%;
  width: 100%;
  fill: currentColor;
  color: inherit;
  display: block
}

.command:hover {
  background: #111727;
  color: white
}
</style>

<script>
/* eslint-disable */

export default {
  data() {
    return {}
  },
  mounted() {
    this.el().style = `top: ${Math.min(this.context.y, window.innerHeight - this.el().clientHeight - 12)}px; left: ${Math.min(this.context.x, window.innerWidth - this.el().clientWidth - 12)}px`
    this.close = (e)=>{
      if(e.target == this.context.target) return
      this.$emit('close', false)
    }
    document.addEventListener('click', this.close)
    document.addEventListener('contextmenu', this.close)
  },
  destroyed(){
    document.removeEventListener('click', this.close)
    document.removeEventListener('contextmenu', this.close)
  },
  updated(){
    this.el().style = `top: ${Math.min(this.context.y, window.innerHeight - this.el().clientHeight - 12)}px; left: ${Math.min(this.context.x, window.innerWidth - this.el().clientWidth - 12)}px`
  },
  props: {
    context: {
        required: true,
        default: false
    },
    isMod: {
      required: false,
      default: false
    },
    isOwner: {
      required: false,
      default: false
    }
  },
  methods: {
    el(){
      return this.$el.children[0]
    },
    sendCommand(name, data){
      this.$emit('command', `${name}${data ? ' ' + data : ''}`)
    },
    mention(displayname){
      this.$emit('mention', displayname)
    },
    reply(message){
      this.$emit('reply', message)
    }
  }
}
</script>
