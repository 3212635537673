<!-- eslint-disable -->
<!--
input component
- emoji
- gifs
- sendmessage (disabled until client)
- reply preview
- props: client, mention (watch prop change), errors

message
- v-switch (types)
- reply_to ({message})

user-card
- xid

widget component
- polls/voting
- top chatter? fixed to top
- resize observer

gif (for messages)
- use preview src
- on hover (or chat focused?) use src
- flag? this.autoPlay
-->
<template>
  <div class="chat__column">
  <!-- modal vertical center -->
    <b-modal
      ref="raid"
      id="modal-center"
      centered
      title=""
      hide-footer
    >
    <img src="https://c.tenor.com/MnP0RuX2Q9UAAAAd/bh187-pirates-of-the-caribbean.gif" width="100%" class="mb-2">
      <b-card-text style="text-align: center;">
       <h3> {{ raidDetails.message}}</h3>
      </b-card-text>
    </b-modal>
    <div class="chat__column-header">
      <!---    <feather-icon
        size="20"
        icon="ChevronsRightIcon"
        class="collapseListIcon"
      />--->
      <center
        style="position: relative; top: 5px; text-align: center !important; font-weight: bold;"
      >
        {{ activeElement }}
      </center>
      <a
        @click.prevent="openSettings"
        title="Chat Settings"
        v-if="!viewerListShow && !settingsShow"
      >
        <feather-icon size="20" icon="SettingsIcon" class="settingsIcon" />
      </a>
      <a
        @click.prevent="openViewerList"
        title="Viewer List"
        v-if="!viewerListShow && !settingsShow"
      >
        <feather-icon size="20" icon="UsersIcon" class="viewerListIcon" />
      </a>
      <a
        @click.prevent="closeViewerList"
        v-if="viewerListShow || settingsShow"
        title="Viewer List"
      >
        <feather-icon size="20" icon="XSquareIcon" class="viewerListIcon" />
      </a>
    </div>
    <div class="chat__column-viewerList" v-if="viewerListShow">
    <div v-if="viewerListLoading">
    <center><b-spinner variant="primary" label="Loading..." class="mr-1" /><span>Updating Viewerlist</span></center>
    </div>
    <h4 class="ml-1 mt-1 mb-1">Brime Viewers</h4>
      <div
        v-for="viewer in chatters"
        :key="viewer.chat_xid"
        class="ml-1"
        style="margin-bottom: 5px;"
      >
        <b-avatar
          size="30"
          :src="
            `https://assets.brimecdn.com/users/${viewer.user_xid}/avatar`
          "
          variant="light-primary"
          class="badge-minimal mr-50"
        /><a :href="`/${viewer.username}`" target="_blank"
          ><span :style="`font-weight: bold; color: ${viewer.chat_color}`">{{
            viewer.display_name
          }}</span></a
        ><span style="font-weight: bold;" v-if="viewer.guest">Guest</span>
      </div>
      <div v-if="multichat && multichat.twitch && multichat.twitch.chatters">
      <h4 class="ml-1 mt-2 mb-1" v-if="multichat && multichat.twitch && multichat.twitch.chatters">Twitch Viewers</h4>
      <div
        v-for="viewer in multichat.twitch.chatters.viewers"
        :key="viewer"
        class="ml-1"
        style="margin-bottom: 5px;"
      >
        <b-avatar
          size="30"
          :src="twitchData[viewer]"
          variant="light-primary"
          class="badge-minimal mr-50"
        /><a :href="`https://twitch.tv/${viewer}`" target="_blank"
          ><span :style="`font-weight: bold; color: ${viewer.chat_color}`">{{
            viewer
          }}</span></a
        ><span style="font-weight: bold;" v-if="viewer.guest">Guest</span>
      </div>
      </div>
      <div v-if="multichat && multichat.trovo && multichat.trovo.chatters">
      <h4 class="ml-1 mt-2 mb-1" v-if="multichat && multichat.trovo">Trovo Viewers</h4>
      <div
        v-for="viewer in multichat.trovo.chatters.all.viewers"
        :key="viewer"
        class="ml-1"
        style="margin-bottom: 5px;"
      >
        <b-avatar
          size="30"
          :src="trovoData[viewer]"
          variant="light-primary"
          class="badge-minimal mr-50"
        /><a :href="`https://trovo.live/${viewer}`" target="_blank"
          ><span :style="`font-weight: bold; color: ${viewer.chat_color}`">{{
            viewer
          }}</span></a
        ><span style="font-weight: bold;" v-if="viewer.guest">Guest</span>
      </div>
      </div>
    </div>
    <div class="chat__column-viewerList" v-if="settingsShow">
      <b-badge
      href="#"
      @click="openPopoutChat"
      variant="primary"
      class="ml-1 mr-1 mb-2 d-block"
    >
      <feather-icon
        icon="LinkIcon"
        class="mr-25"
      />
      <span>Popout Chat</span>
    </b-badge>
    <b-form-checkbox
      name="check-button"
      class="ml-1"
      :checked="ding"
      @change="dingToggle"
      switch
      inline
    >
      Ding on new messages
    </b-form-checkbox><br>
    <b-form-checkbox
      name="check-button"
      class="ml-1 mt-1"
      :checked="timestamps"
      @change="timestampsToggle"
      switch
      inline
    >
      Show timetamps
    </b-form-checkbox>
    </div>
    <div class="messages" data-pause-scrolling="false">
      <chat-message
        v-for="message in messages"
        :timestamps="timestamps"
        :key="message.xid"
        :message="message"
        :active="reply_target ? reply_target.xid == message.xid : false"
        :highlight="highlight === message.xid"
        :mentioned="
          message.content.meta.mentions.findIndex(
            e => e.username == $auth.user.nickname,
          ) != -1 ||
            (message.reply &&
              message.reply.user.username == $auth.user.nickname)
        "
        @reply="setReplyTarget"
        @scrollReply="scrollToReply"
        @mention="setMentionTarget"
        @showUser="setUserTarget"
        @showLink="setLinkTarget"
        @context="setContext"
      />
    </div>
    <div id="continue-chat">
      New messages
      <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M17.71,11.29a1,1,0,0,0-1.42,0L13,14.59V7a1,1,0,0,0-2,0v7.59l-3.29-3.3a1,1,0,0,0-1.42,1.42l5,5a1,1,0,0,0,.33.21.94.94,0,0,0,.76,0,1,1,0,0,0,.33-.21l5-5A1,1,0,0,0,17.71,11.29Z"
        />
      </svg>
    </div>
    <div class="chat__footer">
      <chat-reply
        v-if="reply_target"
        :reply="reply_target"
        @removeReply="removeReplyTarget"
      />
      <chat-input
        @resetMention="resetMentionTarget"
        @send="sendMessage"
        @sendGif="sendGif"
        :mentioned="mention"
        :isMod="perms.mod"
        :isOwner="perms.owner"
      />
    </div>
    <user-card :target="user_target" :channel="channel" />
    <link-card :target="link_target" />
    <chat-context @reply="setReplyTarget" @mention="setMentionTarget" @close="setContext" @command="sendCommand" v-if="context" :context="context" :isMod="perms.mod" :isOwner="perms.owner" />
  </div>
</template>
<style scoped>
.chat__column-viewerList {
  margin-top: 10px;
  overflow: auto;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.chat__column-viewerList::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.chat__column-viewerList {
  -ms-overflow-style: none !important;  /* IE and Edge */
  scrollbar-width: none !important;  /* Firefox */
}
.chat__column-header {
  border-top: 1px;
  border-top-style: solid;
  border-top-color: #111727;
  height: 30px;
  background: #283046;
}
.viewerListIcon {
  position: absolute;
  top: 5px;
  right: 12px;
}
.settingsIcon {
  position: absolute;
  top: 5px;
  left: 12px;
}
.collapseListIcon {
  position: absolute;
  left: 10px;
  top: 5px;
}
.chat__column {
  display: flex;
  flex-direction: column;
  background: #111727;
  padding: 0;
}

@media screen and (min-width: 769px) {
  .chat__column {
    position: fixed;
    right: 0;
    display: flex;
    flex-direction: column;
    margin: 0;
    top: var(--headerHeight);
    height: calc(100% - var(--headerHeight));
    width: max(20vw, 250px);
  }
}

@media screen and (max-width: 768px) {
  .viewerListIcon {
    top: unset;
    right: 5px !important;
    transform: translateY(-15px);
  }
  .chat__column {
    width: 100%;
    flex: 1;
    margin: 0;
    overflow: hidden;
  }
}

.messages {
  flex: 1;
  overflow: auto;
     overflow-y: scroll;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none;  /* Internet Explorer 10+ */   
}
.messages::-webkit-scrollbar {
        display: none;  /* Safari and Chrome */
      }

.input {
  flex-shrink: 0;
  background: #283046;
  display: flex;
  align-items: center;
  width: calc(100% - 1rem);
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.25rem;
  border-radius: 0.25rem;
  border: 2px solid transparent;
  transition: border-color 0.2s;
  position: relative;
}

#continue-chat {
  display: none;
  position: absolute;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  font-size: 0.9rem;
  background: #283046;
  padding: 0.4rem 1rem;
  border-radius: 1rem;
  cursor: pointer;
  white-space: nowrap;
}

#continue-chat svg {
  height: 1rem;
  display: inline-block;
  margin-left: 0.25rem;
}
</style>
<style>
.dark-layout .Vue-Toastification__toast {
border: 1px solid #7367f0!important
}
</style>
<script>
/* eslint-disable */
import ChatMessage from './chat-message.vue'
import ChatInput from './chat-input.vue'
import mqtt from 'mqtt'
import ChatReply from './chat-reply.vue'
import UserCard from './user-card.vue'
import LinkCard from './link-card.vue'
import { BAvatar, BFormCheckbox, BModal, BCardText, BSpinner, BBadge } from 'bootstrap-vue'
import ChatContext from './chat-context.vue'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  props: ['channel', 'user', 'token', 'mobile'],
  data() {
    return {
      channelData: {},
      multiChatConfig: {},
      timestamps: false,
      trovoData: [],
      twitchData: [],
      raidDetails: {},
      chatLang: 'en-us',
      perms: {},
      ding: null,
      context: false,
      chatters: [],
      multichat: {},
      activeElement: 'STREAM CHAT',
      viewerListShow: false,
      settingsShow: false,
      mention: false,
      highlight: false,
      messages: [],
      MAX_MESSAGES: 100,
      reply_target: false,
      user_target: false,
      link_target: false,
    }
  },
  
  methods: {
    showToast(variant, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notification',
          icon: 'ShieldIcon',
          text: 'Chat has been cleared by a moderator.',
          variant,
        },
      },
      {
        position,
        timeout: 6000
      })
    },
    openPopoutChat() {
      console.log(this.channelData)
      window.open(`/${this.channelData.slug}/chat`, '_blank', 'width=350,height=800')
      this.closeViewerList()
    },
  async getTwitchAvi(user){
    let viewers = [user]
    const qs = `query {${viewers
  .map(
    (username, index) => `
  i${index}: user(login: "${username}") {
    profileImageURL(width: 70)
    login
  }`
  )
  .join('')} }`;

  const gqlQuery = await fetch('https://gql.twitch.tv/gql', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Client-ID': 'kimne78kx3ncx6brgo4mv6wki5h1ko',
    },
    body: JSON.stringify({ query: qs }),
  });
  const res = await gqlQuery.json();
  let aviData = {}
  for (const property in res.data) {
    if(res.data[property] != null){
    let user = res.data[property].login
    aviData[user] = res.data[property].profileImageURL;
}
} return aviData[user]
    },
    async getTrovoAvi(user){
      const response = await axios.get(`https://trovo-avi.brime.workers.dev/?channel=${user}`)
      const userData = response.data
      return userData.avatar
    },
    dingToggle(){
      if(!this.ding){
        this.ding = true
        localStorage.setItem('chat-ding', true);
      } else {
        this.ding = false
        localStorage.setItem('chat-ding', false);
      }
    },
    timestampsToggle(){
      if(this.timestamps == false){
        this.timestamps = true
        localStorage.setItem('brime-timestamps', true);
      } else {
        this.timestamps = false
        localStorage.setItem('brime-timestamps', false);
      }
    },
    playDing(){
      var audio = new Audio('https://assets.brimecdn.com/sounds/dink.mp3')
      audio.play();
    },
    async openViewerList() {
      this.viewerListLoading = true
      this.viewerListShow = true
      this.activeElement = 'VIEWER LIST'
      this.toggleChat()
      let {chatters, multichat} = await this.channelChatters(this.channel)
      if(multichat.twitch){
        this.multiChatConfig.twitch = true
      }
      if(multichat.trovo){
        this.multiChatConfig.trovo = true
      }
      const guestRemove = chatters.filter(function( obj ) {
      return obj.username !== null;
      });
      this.chatters = guestRemove.sort((a,b) => (a.username > b.username) ? 1 : ((b.username > a.username) ? -1 : 0))
      this.multichat = multichat
      // Stupid workaround to get Twitch user avatars
      let twitchViewerData = {}
      if(this.multiChatConfig.twitch){
        for (const viewer of this.multichat.twitch.chatters.viewers){
        const u = await this.getTwitchAvi(viewer)
        twitchViewerData[viewer] = u
      }
      this.twitchData = twitchViewerData
      }
      // Stupid workaround to get Trovo user avatars
      let trovoViewerData = {}
      if(this.multiChatConfig.trovo){
      for (let i = 0; i < this.multichat.trovo.chatters.all.viewers.length; i++){
        const u = await this.getTrovoAvi(this.multichat.trovo.chatters.all.viewers[i])
        trovoViewerData[this.multichat.trovo.chatters.all.viewers[i]] = u
      }
      this.trovoData = trovoViewerData
      }
      this.viewerListLoading = false
    },
    async openSettings() {
      this.toggleChat()
      this.settingsShow = true
      this.activeElement = 'CHAT SETTINGS'
    },
    async toggleChat() {
      var messages = document.getElementsByClassName('messages')
      var input = document.getElementsByClassName('input')
      if (messages[0].style.display == 'none') {
        messages[0].style.display = 'block'
        input[0].style.display = 'flex'
      } else {
        messages[0].style.display = 'none'
        input[0].style.display = 'none'
      }
    },
    async closeViewerList() {
      this.toggleChat()
      this.viewerListShow = false
      this.settingsShow = false
      this.activeElement = 'STREAM CHAT'
    },
    async initChat() {
      const chatConnection = {
        connection: {
          host: 'chat.brime.tv',
          port: 443,
          endpoint: '/ws',
          username: 'geeken',
          password: '',
          clean: true, // Reserved session
          connectTimeout: 4000, // Time out
          reconnectPeriod: 4000, // Reconnection interval
          rejectUnauthorized: false,
        },
      }
      let chat_username = ''
      let token = ''
      if (this.$auth.loading == false && this.$auth.user) {
        const chatLangLookup = await this.getChatLang()
        if(chatLangLookup.chat_lang){
          this.chatLang = chatLangLookup.chat_lang.toLowerCase();
        }
        
        chat_username = this.$auth.user.sub.replace('auth0|', '')
        token = await this.$auth.getTokenSilently()
      } else {
        token =
          Math.random()
            .toString(36)
            .substring(2, 8) +
          Math.random()
            .toString(36)
            .substring(2, 8)
        chat_username = `guest`
      }
      if (this.token) {
      chat_username = this.user
      token = this.token
      }
      const username = chat_username
      const { host, port, endpoint } = chatConnection.connection
      const connectUrl = `wss://${host}:${port}${endpoint}`
      try {
        this.client = mqtt.connect(connectUrl, { username, password: token, keepalive: 30, clean: true, reconnectPeriod: 4000, rejectUnauthorized: false })
      } catch {}
      this.client.on('connect', () => {
        this.client.subscribe(`channel/chat/receive/${this.channel}/${this.chatLang}`, err => {
          if (err) console.error(err)
        })
        this.client.subscribe(`channel/chat/receive/${this.channel}`, err => {
          if (err) console.error(err)
        })
        this.client.subscribe(`channel/${this.channel}`, err => {
          if (err) console.error(err)
        })
        this.client.subscribe(`private/${username}`, err => {
          if (err) console.error(err)
        })
      })
      this.client.on('error', error => {
        console.error(error)
      })
      this.client.on('message', (topic, message) => {
        const msg = JSON.parse(message.toString())
        if (this.ding && msg.content){
        this.playDing()
        }
        try {
          const data = JSON.parse(message.toString())
          if(topic == `channel/${this.channel}`){
            this.raidDetails = data
            if(data.event_type == 'outgoing_raid'){
              this.$refs['raid'].show()
              setTimeout(() => {
                window.location=`https://brime.tv/${data.destination.slug}`
              }, 4000)
            }
            return
          }
          if(data.type == 'delete'){
            this.messages.some(item => { 
            if(item.linked_xid === data.targetMsg) // Case sensitive, will only remove first instance
            this.messages.splice(this.messages.indexOf(item),1) 
            })
            return
          }
          if(data.type == 'clear'){
            this.messages = []
            this.showToast('primary','top-right')
            return
          }
          this.messages.push(data)

          if (this.messages.length > this.MAX_MESSAGES) {
            this.messages.splice(0, this.messages.length - this.MAX_MESSAGES)
          }
        } catch (err) {
          console.error('Corrupted message format.')
          console.error(message)
        }
      })
    },
    sendMessage({ content }) {
      if (!this.$auth.user && !this.mobile) {
        this.$auth.loginWithRedirect()
      }
      const message = {
        content,
        reply_target: this.reply_target ? this.reply_target.linked_xid : false,
      }
      this.removeReplyTarget()
      this.resetMentionTarget()
      this.client.publish(
        `channel/chat/send/${this.channel}`,
        JSON.stringify(message),
        { qos: 0, retain: false },
      )
    },
    sendCommand(command){
      this.client.publish(
        `channel/chat/send/${this.channel}`,
        JSON.stringify({
          content: command,
          reply_target: false
        }),
        { qos: 0, retain: false },
      )
      this.context = false
    },
    sendGif(id) {
      this.sendMessage({ content: `/gif ${id}` })
    },
    setReplyTarget(message) {
      this.reply_target = message
      this.$el.querySelector('.chat__input').focus()
    },
    scrollToReply(xid) {
      let re = document.getElementById(`m__${xid}`)
      if (!re) return
      this.highlight = xid
      this.$el.querySelector('.messages').scrollTo({
        top: re.offsetTop,
        behavior: 'smooth',
      })
      setTimeout(() => {
        if (this.highlight == xid) this.highlight = false
      }, 2500)
    },
    removeReplyTarget() {
      this.reply_target = false
    },
    setMentionTarget(user) {
      this.mention = user
      this.$el.querySelector('.chat__input').focus()
    },
    resetMentionTarget() {
      this.mention = false
    },
    setUserTarget(target) {
      this.user_target = target
    },
    setLinkTarget(target) {
      this.link_target = target
    },
    setContext(data){
      this.context = data
    },
    unsubscribe() {
      this.client.unsubscribe(`channel/chat/receive/${this.channel}`)
    },
    async rewindMessages() {
      let r = await fetch(
        `https://api.brime.tv/v1/chat/history/channel/${this.channel}/${this.chatLang}`,
      )
      let items = await r.json()
      let pmessages = []
      for (let item of items) {
        pmessages.push(item.content)
      }
      this.messages.unshift(...pmessages)
      this.chat.scrollTop = this.chat.offsetHeight
    },
    isVisible(ele, container) {
      const { bottom, height, top } = ele.getBoundingClientRect()
      const containerRect = container.getBoundingClientRect()

      return top <= containerRect.top
        ? containerRect.top - top <= height
        : bottom - containerRect.bottom <= height
    },
    async getChannel() {
        this.rewindMessages()
        if(this.$auth.user){
        this.perms = await this.chatPerms(this.channel)
      }
    }
  },
  components: {
    ChatMessage,
    ChatInput,
    ChatReply,
    UserCard,
    LinkCard,
    ChatContext,
    BAvatar,
    BFormCheckbox,
    BModal,
    BCardText,
    BSpinner,
    ToastificationContent,
    BBadge,
  },
  async created() {
    await this.getChannel()
    this.initChat()
  },
  watch: {
    channel: [{
      handler: 'getChannel'
    }]
  },
  async mounted() {
      this.pauseGifs = () => {
      this.$el.querySelectorAll('.gif__element').forEach(e => {
        e.src = e.dataset.static
      })
    }
    this.channelData = await this.channelLookupByXID(this.channel)
    window.addEventListener('blur', this.pauseGifs)
    const getDing = localStorage.getItem('chat-ding')
    if(getDing == 'true'){
      this.ding = true
    } else {
      this.ding = false
    }
    const ts = localStorage.getItem('brime-timestamps');
    if(ts){
      this.timestamps = ts == 'true'
    }

    function debounce(func, timeout = 250) {
      let timer
      return (...args) => {
        clearTimeout(timer)
        timer = setTimeout(() => {
          func.apply(this, args)
        }, timeout)
      }
    }

    this.resizeHandler = debounce(() => {
      document.querySelector('.app-content').style = `--headerHeight: ${
        document.querySelector('.header-navbar').clientHeight
      }px;`
    }, 150)
    if(!this.$router.currentRoute.path.includes('/chat')){
      this.resizeHandler()
      window.addEventListener('resize', this.resizeHandler)
    }

    /* eslint-disable */
    const chat = document.querySelector('.messages')
    this.chat = chat
    const continueChat = document.getElementById('continue-chat')

    continueChat.addEventListener('click', () => {
      chat.scrollTop = chat.scrollHeight + chat.offsetHeight
    })

    this.chatScroll = () => {
      let gifs = chat.querySelectorAll('.gif__element')
      if (document.hasFocus()) {
        for (let gif of gifs) {
          if (this.isVisible(gif, chat)) {
            gif.dataset.visible = 'true'
            if (gif.src == gif.dataset.gif) continue
            gif.src = gif.dataset.gif
            continue
          }

          gif.dataset.visible = 'false'
          if (gif.src == gif.dataset.static) continue
          gif.src = gif.dataset.static
        }
      }

      if (
        chat.scrollHeight > chat.offsetHeight &&
        chat.scrollHeight - chat.scrollTop - chat.offsetHeight > 64
      ) {
        continueChat.style.display = 'block'
        return (chat.dataset.pauseScrolling = 'true')
      }
      continueChat.style.display = 'none'
      return (chat.dataset.pauseScrolling = 'false')
    }

    chat.addEventListener('scroll', this.chatScroll)
    window.addEventListener('focus', this.chatScroll)
    window.addEventListener('beforeunload', this.unsubscribe)
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeHandler)
    window.removeEventListener('blur', this.pauseGifs)
    window.removeEventListener('focus', this.chatScroll)
    this.chat.removeEventListener('scroll', this.chatScroll)
    this.client.unsubscribe(`channel/chat/receive/${this.channel}`)
  },
}
</script>
