<!-- eslint-disable -->
<template>
    <div class="link__card">
        <div class="link__card__wrapper" v-if="link_info">
            <div class="link__info">
                <span class="link__host" v-if="link_info.site">{{link_info.site}}</span>
                <a class="link__title" :href="link_info.href" target="_blank">{{link_info.title}}</a>
                <p v-if="link_info.description" class="link__description">{{link_info.description.slice(0, 80)}}...</p>
            </div>
            <span class="close" @click="link_info = false">x</span>
        </div>
    </div>
</template>

<style scoped>
.link__host {
    font-size: 0.85em;
    opacity: 0.9;
    display: block;
    margin-bottom: 0.35em;
}

.link__title {
    font-size: 1.1em;
}

.link__description {
    margin: 0;
    margin-top: 0.5em;
    font-size: 0.9em;
}

.link__card {
    color: white;
    position: fixed;
    top: 50px;
    left: 50px;
    z-index: 10;
    width: 350px;
    margin-left: -175px;
}

@media screen and (max-width: 768px) {
    .link__card {
        width: 100vw;
        left: 0px !important;
        margin: 0;
    }
}

.link__card__wrapper {
    display: flex;
    align-items: flex-start;
    background-color: #111727;
    border-radius: 0.75rem;
    padding: 1.5rem;
    box-shadow: 0px 0px 40px rgba(4, 6, 14, 0.55);
    background-size: cover;
}

.link__card__wrapper::before {
    content: '';
    background: linear-gradient(90deg, rgba(17, 23, 39, 1), rgba(17, 23, 39, .85));
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.link__card__wrapper * {
    z-index: 1;
}

.link__thumb {
    margin-right: 1rem;
    height: 60px;
    flex-shrink: 0;
}

.link__thumb img {
    border-radius: 0.25em;
    height: 100%;
    width: auto;
    max-width: 120px;
    object-fit: cover;
}

.link__info {
    padding-left: 0.25rem;
    padding-top: 0.25rem;
}

.close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    font-size: 1.25rem;
    color: white;
    cursor: pointer;
}
</style>

<script>
/* eslint-disable */

export default {
    data(){
        return {
            link_info: false
        }
    },
    updated(){
        this.$el.style = `left: ${Math.min(this.left, window.innerWidth - 175)}px; top: ${Math.min(this.top, window.innerHeight - this.$el.clientHeight)}px;`;
        if(this.link_info && this.link_info.image){
            this.$el.querySelector('.link__card__wrapper').style = `background-image: url(${this.link_info.image})`
        } else {
            this.$el.querySelector('.link__card__wrapper').style = null
        }
    },
    watch: { 
      	target: function(nv, ov) {
            let {element, url} = nv
            if(!element) return
            const self = this
            fetch(`https://resolver.brimecdn.com/resolve?url=${url}`)
            .then(r=>r.json())
            .then((json)=>{
                if(json.error){
                    self.link_info = false
                    return window.open(url, '_blank')
                }

                self.link_info = {
                    ...json,
                    href: json.href || url
                }

                let rect = element.getBoundingClientRect()
                self.left = rect.left
                self.top = rect.bottom
            })
        }
    },
    props: {
        target: {
            required: true
        }
    }
}
</script>